import React, { useState } from 'react'
import HeaderOTA from 'components/Header/HeaderOTA/index';
import { Input } from 'components/Input/index';
import { Text, Img, Line, Button } from "components";
import { useNavigate } from "react-router-dom";
import HeaderOTAAdmin from 'components/Header/HeaderOTAAdmin/index';
import HeaderOTAMobileEpic from "components/Header/HeaderOTAMobileEpic/index";
import Footerepic from 'components/Footer/Footerepic/index';
import { useAuth } from 'AuthContext';
import LoginPage from "components/Login/index";
import { MdOutlineSwapVert, MdSearch } from "react-icons/md";
import SignupPage from "components/SignUp/index";


const NavigatePage = () => {
    const navigate = useNavigate();
    const { setIsLoggedIn } = useAuth();

    //setIsLoggedIn(true);

    const [isPopup1Open, setIsPopup1Open] = useState(false);
    const [isPopup2Open, setIsPopup2Open] = useState(false);

    const openPopup1 = () => {
        setIsPopup1Open(true);
    };

    const openPopup2 = () => {
        setIsPopup2Open(true);
    };

    const closePopup1 = () => {
        setIsPopup1Open(false);
    };

    const closePopup2 = () => {
        setIsPopup2Open(false);
    };

    function handleNavigate12() {
        navigate("/about-us");
    }

    return (
        <>

            <div className='fixed w-full'>
                <HeaderOTA openPopup1={openPopup1} className="fixed invisible lg:visible" />
                <LoginPage isOpen={isPopup1Open} openPopup2={openPopup2} closePopup1={closePopup1} />
                <SignupPage isOpen={isPopup2Open} closePopup2={closePopup2} />
                <HeaderOTAMobileEpic openPopup1={openPopup1} className="visible fixed overflow-hidden lg:invisible lg:hidden" />
            </div>

            <div className="w-full font-montserrat bg-[#F5F5F5] h-fit xs:pt-[160px] lg:pt-[92px] md:hidden lg:block">
                <text className="text-[15px]">
                    Only available on mobile for now
                </text>
            </div>

            {/* mobile view */}
            <div className="w-full font-montserrat bg-[#F5F5F5] h-fit xs:pt-[160px] lg:pt-[92px] md:block lg:hidden">
                <div>
                    <Img
                        src="images/navbg.jpg"
                        className="h-[1700px] object-cover brightness-50"
                        alt="navbg"
                    />
                    <div className="absolute top-[15%] px-[50px]">
                        <div className="w-[80%]">
                            <text className="text-[74px] text-[#FFFFFF] font-semibold">
                                Discover the routes to anywhere 
                            </text>
                        </div>
                        <div className="pt-[100px]">
                            <div className="bg-[#FAFAFA] p-[70px] rounded-[30px]">
                                <div>
                                    <div>
                                        <text className="text-[#B0ADAD] text-[31px] font-semibold">
                                            TRAVEL FROM
                                        </text>
                                    </div>
                                    <div className="text-[10px]">
                                        <input className="h-[100px] w-full text-[#555555] text-[31px] placeholder:text-[#B0ADAD] p-[20px] bg-[#F4F4F4] rounded-[10px] border-[#B0ADAD] border-[0.5px]" 
                                        placeholder="Enter Place"/>
                                    </div>
                                </div>
                                <div className="mt-[50px] flex justify-end">
                                    <button className="bg-[#FAFAFA] border-[#B0ADAD] border-[0.5px] rounded-full p-[20px]">
                                        <MdOutlineSwapVert size={54}/>
                                    </button>
                                </div>
                                <div className="mt-[50px]">
                                    <div>
                                        <text className="text-[#B0ADAD] text-[31px] font-semibold">
                                            TRAVEL TO
                                        </text>
                                    </div>
                                    <select
                                        className="h-[100px] w-full text-[#555555] text-[31px] p-[20px] bg-[#F4F4F4] rounded-[10px] border-[#B0ADAD] border-[0.5px]"
                                    >
                                        <option value="KLIA">KLIA</option>
                                        <option value="KLIA2">KLIA2</option>
                                    </select>
                                </div>
                                <div className="mt-[200px]">
                                    <button className="h-[100px] bg-[#2A3075] hover:bg-[#15194B] rounded-[10px] p-[20px] w-full">
                                        <div className="flex justify-center gap-[20px]">
                                            <MdSearch size={54} className="text-[#FFFFFF]"/>
                                            <text className="text-[31px] text-[#FFFFFF]">
                                                SEE ALL OPTIONS
                                            </text>
                                        </div>
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>
                    
                </div>
            </div>

            

            <div className="md:mt-[10px] lg:mt-[500px]">
                <Footerepic />
            </div>


        </>
    );

};

export default NavigatePage;