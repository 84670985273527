import React, { useState } from 'react'
import HeaderOTA from 'components/Header/HeaderOTA/index';
import { Input } from 'components/Input/index';
import { Text, Img, Line, Button } from "components";
import { useNavigate } from "react-router-dom";
import HeaderOTAAdmin from 'components/Header/HeaderOTAAdmin/index';
import HeaderOTAMobileEpic from "components/Header/HeaderOTAMobileEpic/index";
import Footerepic from 'components/Footer/Footerepic/index';
import { useAuth } from 'AuthContext';
import LoginPage from "components/Login/index";
import { MdPerson, MdDirectionsWalk, MdLocationOn, MdLink, MdChevronRight, MdDirectionsSubway } from "react-icons/md";
import { FaBus, FaTicketAlt } from "react-icons/fa";
import SignupPage from "components/SignUp/index";


const Subnav3Page = () => {
    const navigate = useNavigate();
    const { setIsLoggedIn } = useAuth();

    //setIsLoggedIn(true);

    const [isPopup1Open, setIsPopup1Open] = useState(false);
    const [isPopup2Open, setIsPopup2Open] = useState(false);

    const openPopup1 = () => {
        setIsPopup1Open(true);
    };

    const openPopup2 = () => {
        setIsPopup2Open(true);
    };

    const closePopup1 = () => {
        setIsPopup1Open(false);
    };

    const closePopup2 = () => {
        setIsPopup2Open(false);
    };

    return (
        <>

            <div className='fixed w-full'>
                <HeaderOTA openPopup1={openPopup1} className="fixed invisible lg:visible" />
                <LoginPage isOpen={isPopup1Open} openPopup2={openPopup2} closePopup1={closePopup1} />
                <SignupPage isOpen={isPopup2Open} closePopup2={closePopup2} />
                <HeaderOTAMobileEpic openPopup1={openPopup1} className="visible fixed overflow-hidden lg:invisible lg:hidden" />
            </div>

            <div className="w-full font-montserrat h-fit xs:pt-[160px] lg:pt-[92px] md:hidden lg:block">
                <text className="text-[15px]">
                    Only available on mobile for now
                </text>
            </div>

            {/* mobile view */}
            <div className="w-full font-montserrat h-fit xs:pt-[160px] lg:pt-[92px] md:block lg:hidden">
                
                <div className="flex items-center pt-[50px] px-[50px]">
                    <MdDirectionsSubway size={60} className="text-[#874178]"/>
                    <div className="ml-[30px]">
                        <text className="text-[42px] text-[#1E1E1E] font-semibold">
                            Petronas Towers to Batu Caves
                        </text>
                    </div>
                </div>

                <div className="p-[50px] h-fit w-screen">
                    <div className="flex">
                        <div className="mr-[50px]">
                            <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                MYR 3 - 6
                            </text>
                        </div>
                        <div className="flex">
                            <MdPerson size={51} className="text-[#1E1E1E] mr-[20px]"/>
                            <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                1
                            </text>
                        </div>
                    </div>

                    <div className="flex pt-[50px] ">
                        <div className="grid mr-[150px]">
                            <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                Leaving
                            </text>
                            <text className="font-medium text-[24px] text-[#1E1E1E]">
                                Tue, 7 May
                            </text>
                        </div>
                        <div className="grid">
                            <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                Total duration
                            </text>
                            <text className="font-medium text-[24px] text-[#1E1E1E]">
                                52min
                            </text>
                        </div>
                    </div>
                </div>

                <div className="bg-[#FFFFF] border-[#B0ADAD] border-[0.5px] p-[50px] h-fit w-screen">
                    <div className="flex justify-between">
                        <text className="font-semibold text-[34px] text-[#1E1E1E]">
                            Departing
                        </text>
                        <text className="font-medium text-[28px] text-[#1E1E1E]">
                            Tue, 7 May 2024
                        </text>
                    </div>

                    <div className="flex items-center pt-[50px]">
                        <MdDirectionsWalk size={56} className="text-[#555555]"/>
                        <text className="font-medium text-[28px] text-[#1E1E1E] ml-[20px]">
                            Petronas Towers
                        </text>
                        <text className="font-regular text-[24px] text-[#1E1E1E] ml-[30px]">
                            17min • 1.1 km
                        </text>
                    </div>

                    <div className="flex items-center justify-between pt-[50px]">
                        <text className="font-regular text-[24px] text-[#1E1E1E]">
                            Ticket 1
                        </text>
                        <div className="flex items-center bg-[#ECECEC] rounded-[2px] px-[15px] py-[5px]">
                            <FaTicketAlt size={48} className="text-[#555555]"/>
                            <text className="font-regular text-[24px] text-[#555555] ml-[30px]">
                                MYR 2 - 4
                            </text>
                        </div>
                    </div>

                    <div className="flex items-center pt-[50px]">
                        <text className="font-medium text-[28px] text-[#1E1E1E] ml-[20px]">
                            06:45
                        </text>
                        <MdDirectionsSubway size={56} className="text-[#874178] ml-[50px]"/>
                        <div className="grid ml-[100px]">
                            <text className="font-medium text-[28px] text-[#1E1E1E]">
                                Ampang Park
                            </text>
                            <text className="font-regular text-[24px] text-[#555555]">
                                Kuala Lumpur
                            </text>
                        </div>
                    </div>

                    <div className="flex items-center ml-[170px] mt-[20px]">
                        <div className="h-[150px] bg-[#874178] w-[1.5px]"></div>
                        <div className="ml-[140px]">
                            <text className="font-regular text-[28px] text-[#555555]">
                                5min
                            </text>
                        </div>
                    </div>

                    <div className="flex items-center pt-[20px]">
                        <text className="font-medium text-[28px] text-[#1E1E1E] ml-[20px]">
                            06:50
                        </text>
                        <MdLocationOn size={56} className="text-[#874178] ml-[50px]"/>
                        <div className="grid ml-[100px]">
                            <text className="font-medium text-[28px] text-[#1E1E1E]">
                                Kampung Batu
                            </text>
                            <text className="font-regular text-[24px] text-[#555555]">
                                Kuala Lumpur
                            </text>
                        </div>
                    </div>

                    <div className="flex items-center pt-[50px]">
                        <text className="font-regular text-[28px] text-[#555555]">
                            15min Transfer
                        </text>
                    </div>   

                    <div className="flex items-center justify-between pt-[50px]">
                        <text className="font-regular text-[24px] text-[#1E1E1E]">
                            Ticket 2
                        </text>
                        <div className="flex items-center bg-[#ECECEC] rounded-[2px] px-[15px] py-[5px]">
                            <FaTicketAlt size={48} className="text-[#555555]"/>
                            <text className="font-regular text-[24px] text-[#555555] ml-[30px]">
                                MYR 1 - 2
                            </text>
                        </div>
                    </div>   

                    <div className="flex items-center pt-[50px]">
                        <text className="font-medium text-[28px] text-[#1E1E1E] ml-[20px]">
                            06:55
                        </text>
                        <MdDirectionsSubway size={56} className="text-[#874178] ml-[50px]"/>
                        <div className="grid ml-[100px]">
                            <text className="font-medium text-[28px] text-[#1E1E1E]">
                               Kampung Batu
                            </text>
                            <text className="font-regular text-[24px] text-[#555555]">
                                Kuala Lumpur
                            </text>
                        </div>
                    </div>

                    <div className="flex items-center ml-[170px] mt-[20px]">
                        <div className="h-[150px] bg-[#874178] w-[1.5px]"></div>
                        <div className="ml-[140px]">
                            <text className="font-regular text-[28px] text-[#555555]">
                                7min
                            </text>
                        </div>
                    </div>

                    <div className="flex items-center pt-[20px]">
                        <text className="font-medium text-[28px] text-[#1E1E1E] ml-[20px]">
                            07:39
                        </text>
                        <MdLocationOn size={56} className="text-[#874178] ml-[50px]"/>
                        <div className="grid ml-[100px]">
                            <text className="font-medium text-[28px] text-[#1E1E1E]">
                                Batu Caves
                            </text>
                            <text className="font-regular text-[24px] text-[#555555]">
                                Selangor
                            </text>
                        </div>
                    </div>              

                </div>

                <div className="pt-[50px] px-[50px]">
                    <div className="bg-[#FFFFF] border-[#B0ADAD] border-[0.5px] rounded-[10px] p-[40px] flex justify-between">
                        <div>
                            <text className="font-semibold text-[34px] text-[#1E1E1E]">
                                Accomodations near Batu Caves
                            </text>
                        </div>
                        <MdChevronRight size={55} className="text-[#1E1E1E]"/>
                    </div>
                </div>

                <div className="pt-[100px]">
                    <div className="bg-[#FFFFF] border-[#B0ADAD] border-[0.5px] p-[50px] h-fit w-screen">
                        <div className="flex items-center">
                            <div>
                                <Img
                                    src="images/rapid.png"
                                    className="w-[250px] object-cover"
                                    alt="rapidkl"
                                />
                            </div>
                            <div className="ml-[50px]">
                                <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                    Rapid KL
                                </text>
                            </div>
                            <div className="bg-[#2A3075] px-[10px] py-[5px] ml-[20px]">
                                <text className="text-[20px] text-[#FFFFFF]">
                                    Pyl
                                </text>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 items-start gap-[10px] pt-[50px]">
                            <div className="">
                                <div>
                                    <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                        Duration
                                    </text>
                                </div>
                                <div>
                                    <text className="font-medium text-[24px] text-[#1E1E1E]">
                                        13 minutes
                                    </text>
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                        Estimated Price
                                    </text>
                                </div>
                                <div>
                                    <text className="font-medium text-[24px] text-[#1E1E1E]">
                                        MYR 2 - 4
                                    </text>
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                        How often
                                    </text>
                                </div>
                                <div>
                                    <text className="font-medium text-[24px] text-[#1E1E1E]">
                                        Every 10 minutes
                                    </text>
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                        Schedules at
                                    </text>
                                </div>
                                <div>
                                    <a href="https://www.ktmb.com.my/Komuter.html" className="flex items-center">
                                        <text className="font-medium text-[24px] text-[#2A3075]">
                                            Rapid KL
                                        </text>
                                        <MdLink size={48} className="text-[#2A3075] ml-[10px]"/>
                                    </a>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-[50px]">
                    <div className="bg-[#FFFFF] border-[#B0ADAD] border-[0.5px] p-[50px] h-fit w-screen">
                        <div className="flex items-center">
                            <div>
                                <Img
                                    src="images/ktm.png"
                                    className="w-[250px] object-cover"
                                    alt="ktm"
                                />
                            </div>
                            <div className="ml-[50px]">
                                <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                KTM Komuter
                                </text>
                            </div>
                            <div className="bg-[#2A3075] px-[10px] py-[5px] ml-[20px]">
                                <text className="text-[20px] text-[#FFFFFF]">
                                    Pulau Sebang
                                </text>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 items-start gap-[10px] pt-[50px]">
                            <div className="">
                                <div>
                                    <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                        Duration
                                    </text>
                                </div>
                                <div>
                                    <text className="font-medium text-[24px] text-[#1E1E1E]">
                                        7 minutes
                                    </text>
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                        Estimated Price
                                    </text>
                                </div>
                                <div>
                                    <text className="font-medium text-[24px] text-[#1E1E1E]">
                                        MYR 1 - 2
                                    </text>
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                        How often
                                    </text>
                                </div>
                                <div>
                                    <text className="font-medium text-[24px] text-[#1E1E1E]">
                                        Hourly
                                    </text>
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    <text className="font-semibold text-[28px] text-[#1E1E1E]">
                                        Schedules at
                                    </text>
                                </div>
                                <div>
                                    <a href="https://www.ktmb.com.my/Komuter.html" className="flex items-center">
                                        <text className="font-medium text-[24px] text-[#2A3075]">
                                            KTM Intercity
                                        </text>
                                        <MdLink size={48} className="text-[#2A3075] ml-[10px]"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            

            <div className="md:mt-[10px] lg:mt-[500px]">
                <Footerepic />
            </div>


        </>
    );

};

export default Subnav3Page;