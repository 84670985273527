export const featuredData = [
  {
    title: "Private Tour KL",
    description: "Get a bird's-eye view over Kuala Lumpur.",
    author: "Aliahchase",
    image: "/images/kltour.jpg",
    alt: "kltour",
    theme: 1,
    message: 'Kuala Lumpur',
    days: '5',
    tags: ["City Tour", "Architecture", "Design"],
    saves: 233,
  },
  {
    title: "Private Cameron Tour",
    description: "Enjoy the flexibility of independent travel.",
    author: "Aliahchase",
    image: "/images/cameron.jpg",
    alt: "cameron",
    theme: 1,
    message: 'Cameron Highland',
    days: '3',
    tags: ["Farms", "Adventure", "Botanical Gardens"],
    saves: 201,
  },
  {
    title: "Waterfall at Batu Caves ",
    description: "Have a refreshing splash and tranquil spot for visitors",
    author: "Aliahchase",
    image: "/images/batucaves.jpg",
    alt: "batucaves",
    theme: 1,
    message: 'Batu Caves',
    days: '1',
    tags: ["Waterfall", "Caves"],
    saves: 123,
  },
  {
    title: "Glowing Kuala Selangor",
    description: "Experience the natural phenomenon.",
    author: "Aliahchase",
    image: "/images/firefly.jpg",
    alt: "kuala selangor",
    theme: 1,
    message: 'Kuala Selangor',
    days: '1',
    tags: ["Waterfall", "Gardens", "Firefly Park"],
    saves: 222,
  },
  // {
  //   title: "Glowing Kuala Selangor",
  //   description: "Experience the natural phenomenon.",
  //   author: "Aliahchase",
  //   image: "/images/firefly.jpg",
  //   alt: "kuala selangor",
  //   theme: 1,
  //   message: 'Kuala Selangor',
  //   days: '1',
  //   tags: ["Waterfall", "Gardens", "Firefly Park"],
  //   saves: 222,
  // },
];

export const AsData = [
  {
    id: "17",
    title: "Guided Rock Climbing",
    description: "Climb the rocks of Batu Caves.",
    author: "Aliahchase",
    image: "images/climb.jpg",
    alt: "rockclimb",
    theme: 1,
    message: 'Batu Caves',
    destinatio: "Batu Caves",
    days: '1',
    tags: ["Mountain", "Caves"],
    saves: 133,
  },
  {
    id: "21",
    title: "Train Trip to Dabong",
    description: "Hop on a train to venture Dabong.",
    author: "Aliahchase",
    image: "images/ktmdabong.jpg",
    alt: "dabong",
    theme: 1,
    message: 'Dabong',
    destinatio: 'Dabong',
    days: '3',
    tags: ["Railway Station", "Adventure"],
    saves: 411,
  },
  {
    id: "20",
    title: "Guided Snorkeling Trip",
    description: "Discover Sabah's sea safely.",
    author: "Aliahchase",
    image: "images/snorkeling.jpg",
    alt: "Sabah",
    theme: 1,
    message: 'Sabah',
    destinatio: 'Kota Kinabalu',
    days: '1',
    tags: ["Island", "Beach"],
    saves: 323,
  },
  {
    id: "19",
    title: "Scuba Diving",
    description: "Explore the sea with guidance.",
    author: "Aliahchase",
    image: "images/scuba.jpg",
    alt: "Sabah",
    destinatio: 'Redang Island',
    theme: 1,
    message: 'Kuala Selangor',
    days: '1',
    tags: ["Island", "Beach"],
    saves: 122,
  },
];

export const foodData = [
  {
    id: "22",
    title: "Malacca Food Trip",
    description: "Experience cooking local cuisine.",
    author: "Aliahchase",
    image: "images/foodMalacca.jpg",
    alt: "foodMalacca",
    destinatio: 'Malacca',
    theme: 1,
    message: 'Melaka',
    days: '2',
    tags: ["Food Outlet", "Cultural Center"],
    saves: 133,
  },
  {
    id: "7",
    title: "Buffet at KL Tower",
    description: "Experience buffet with a great view.",
    author: "Aliahchase",
    image: "images/foodKL.jpg",
    alt: "foodKL",
    theme: 1,
    destinatio: 'Kuala Lumpur',
    message: 'Kuala Lumpur',
    days: '1',
    tags: ["Food Outlet", "Adventure"],
    saves: 411,
  },
  {
    id: "23",
    title: "Heritage Food Hunting",
    description: "Experience fantastic food from Penang.",
    author: "Aliahchase",
    image: "images/foodPen.jpg",
    alt: "foodpenang",
    theme: 1,
    destinatio: 'Penang',
    message: 'Penang',
    days: '2',
    tags: ["Food Outlet", "Cultural Center"],
    saves: 323,
  },
  {
    id: "9",
    title: "Private Hands-on Cooking",
    description: "Experience cooking local cuisine.",
    author: "Aliahchase",
    image: "images/foodPrv.jpg",
    alt: "foodprivate",
    theme: 1,
    destinatio: 'Kuala Lumpur',
    message: 'Johor',
    days: '1',
    tags: ["Cultural Center"],
    saves: 122,
  },
];
