export const CheckBoxAdmin = [
  {
    id: "01",
    name: "Read",
  },
  {
    id: "02",
    name: "Create",
  },
  {
    id: "03",
    name: "Update",
  },
  {
    id: "04",
    name: "Delete",
  },
  {
    id: "05",
    name: "Process",
  },
];
